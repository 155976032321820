
::v-deep .el-breadcrumb {
    padding-bottom: 20px;
}

.course-ware-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
